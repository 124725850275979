import React from "react"
import { Box, Text } from "@theme-ui/components"
import { LanguageContext } from "../locales/langContext"

const KeyTakeawaysRecap = ({ record }) => {
  return (
    <LanguageContext.Consumer>
      {t => (
        <Box
          sx={{
            backgroundColor: "hsla(111, 47.10%, 80.00%, 0.10)",
            border: "1px solid",
            borderColor: "hsla(111, 47.10%, 80.00%, 0.2)",
            borderLeftColor: "primary",
            padding: [3, "2rem"],
            color: "dark",
            marginBottom: [3, 4],
            marginTop: [3, 4],
            "&:last-child": {
              marginBottom: 0,
            },
            p: {
              fontSize: [2],
              color: "primary",
              "&:last-child": {
                marginBottom: 0,
              },
            },
            ul: {
              listStyleType: "none",
              margin: 0,
              padding: 0,
              "&:last-child": {
                marginBottom: 0,
              },
            },
            li: {
              fontSize: [1],
              position: "relative",
              paddingLeft: "1.5em",
              marginBottom: "1em",
              lineHeight: "1.3",
              "&:before": {
                content: '"✔"',
                position: "absolute",
                left: 0,
                color: "primary",
              },
              "&:last-of-type": {
                marginBottom: 0,
              },
            },
          }}
        >
          <Box dangerouslySetInnerHTML={{ __html: record.text }} />
        </Box>
      )}
    </LanguageContext.Consumer>
  )
}

export default KeyTakeawaysRecap
