import React from "react"
import { Box, Text } from "@theme-ui/components"
import { LanguageContext } from "../locales/langContext"
const Toc = ({ article }) => {
  // Extract h2 headings from the DAST structure
  const headings = article?.document?.children?.reduce((acc, node) => {
    if (node.type === "heading" && node.level === 2) {
      // Create a slug from the heading text for the anchor link
      const slug = node.children[0].value
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)/g, "")

      acc.push({
        text: node.children[0].value,
        slug: slug,
      })
    }
    return acc
  }, [])

  console.log(headings)

  if (!headings?.length) {
    return null
  }

  return (
    <LanguageContext.Consumer>
      {t => (
        <Box
          sx={{
            paddingX: "1.5rem",
            borderLeft: "1px solid",
            borderColor: "primary",
            height: "fit-content",
            position: ["relative", "relative", "sticky"],
            top: ["auto", "auto", "200px"],
          }}
        >
          <Text
            as="p"
            sx={{
              fontWeight: "bold",
              color: "dark",
              marginTop: [0],
              marginBottom: "1rem",
            }}
          >
            {t.toc}
          </Text>
          <Box
            as="ul"
            sx={{
              listStyle: "none",
              paddingLeft: 0,
              margin: 0,
            }}
          >
            {headings.map((heading, index) => (
              <Box
                as="li"
                key={index}
                sx={{
                  fontSize: ["13px"],
                  marginBottom: "0.5rem",
                  borderBottom: "1px solid #eee",
                  paddingBottom: "0.5rem",
                  "&:last-of-type": {
                    paddingBottom: "0",
                    marginBottom: "0",
                    borderBottom: "none",
                  },
                  a: {
                    color: "primary",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  },
                }}
              >
                <a href={`#${heading.slug}`}>
                  {index + 1}. {heading.text}
                </a>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </LanguageContext.Consumer>
  )
}

export default Toc
