import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import HeroHeader from "../components/heroHeader"
import TextBlock from "../components/textBlock"
import {
  getArticlePath,
  getBlogPath,
  getNewsArticlePath,
  getNewsPath,
} from "../components/pathUtils"
import { ChevronLeft } from "react-feather"
import { LanguageContext } from "../locales/langContext"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Flex, Box, Container, Grid, Image } from "@theme-ui/components"
import { StructuredText } from "react-datocms"
import Toc from "../components/Toc"
import { isHeading, renderRule } from "datocms-structured-text-utils"
import KeyTakeawaysRecap from "../components/keyTakeawaysRecap"
import GatsbyImage from "gatsby-image"

const Article = ({ data: { article }, pageContext, location }) => {
  console.log(article.content.value !== null)
  const lang = pageContext.locale

  const i18nPaths = article._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value:
        pageContext.category === "blog"
          ? getArticlePath(article, path.locale)
          : getNewsArticlePath(article, path.locale),
    }
  })

  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    headline: article.seo ? article.seo.title || article.title : article.title,
    author: {
      "@type": "Organization",
      name: "Gavazzi S.p.a.",
      logo: {
        "@type": "ImageObject",
        url:
          "https://www.gavazzispa.it/static/gavazzi-logo-cee9f73306f0b4c4e1f5901cdccae880.png",
      },
    },
    image: {
      "@type": "ImageObject",
      url: article.heroImage.fluid.src,
    },
    datePublished: article.meta.firstPublishedAtIso,
    publisher: {
      "@type": "Organization",
      name: "Gavazzi S.p.a.",
      logo: {
        "@type": "ImageObject",
        url:
          "https://www.gavazzispa.it/static/gavazzi-logo-cee9f73306f0b4c4e1f5901cdccae880.png",
      },
    },
    dateModified: article.meta.updatedAt,
    description: article.seo ? article.seo.description : null,
    url: `https://www.gavazzispa.it${pageContext.category === "blog"
      ? getArticlePath(article, lang)
      : getNewsArticlePath(article, lang)
      }`,
    identifier: `https://www.gavazzispa.it${pageContext.category === "blog"
      ? getArticlePath(article, lang)
      : getNewsArticlePath(article, lang)
      }`,
  }

  return (
    <Layout
      lang={lang}
      location={location}
      i18nPaths={i18nPaths}
      allSlugLocales={article._allSlugLocales}
    >
      <LanguageContext.Consumer>
        {t => (
          <>
            <HelmetDatoCms seo={article.seoMetaTags}>
              <html lang={lang} />
              <script type="application/ld+json">
                {JSON.stringify(ldJson)}
              </script>
            </HelmetDatoCms>
            <HeroHeader
              title={article.title}
              subtitle={article.subtitle}
              heroImage={article.heroImage}
              highlight={article.highlight}
            />
            <Container sx={{ marginTop: [5], marginBottom: [7] }}>
              <Grid
                columns={[1, 1, "3fr 5fr", "2fr 5fr"]}
                gap={("32px", "32px", "32px", "64px")}
                sx={{ marginBottom: [7] }}
              >
                {article.content.value !== null && (
                  <Toc article={article.content.value} />
                )}
                {article.content.value !== null && (
                  <Box
                    sx={{
                      color: "dark",
                      h2: {
                        marginTop: [5],
                        marginBottom: [4],
                        fontSize: [4, 5],
                        scrollMarginTop: ["150px", "150px"],
                        "&::before": {
                          content: '""',
                          display: "block",
                          height: "80px",
                          marginTop: "-80px",
                          visibility: "hidden",
                        },
                      },
                      h3: {
                        // color: "primary",
                        fontSize: [3, 3],
                        marginTop: [3],
                        marginBottom: [2, 2],
                      },
                      p: {
                        lineHeight: 1.5,
                        mb: ["16px"],
                      },
                      ul: {
                        li: {
                          marginBottom: [1],
                          p: {
                            marginBottom: [0],
                          },
                        },
                      },
                    }}
                  >
                    <StructuredText
                      data={article.content}
                      renderBlock={({ record }) => {
                        console.log(record.__typename)
                        switch (record.__typename) {
                          case "DatoCmsKeyTakeawaysRecap":
                            return <KeyTakeawaysRecap record={record} />
                          case "DatoCmsBlogImage":
                            return <Image src={record.image.fluid.src} sx={{ mb: [2, 3] }} />
                          default:
                            return null
                        }
                      }}
                      customNodeRules={[
                        renderRule(
                          node => isHeading(node) && node.level === 2,
                          ({
                            adapter: { renderNode },
                            node,
                            children,
                            key,
                          }) => {
                            const slug = node.children[0].value
                              .toLowerCase()
                              .replace(/[^a-z0-9]+/g, "-")
                              .replace(/(^-|-$)/g, "")

                            return renderNode(
                              () => <h2 id={slug}>{children}</h2>,
                              { key },
                              children
                            )
                          }
                        ),
                      ]}
                    />
                  </Box>
                )}
              </Grid>
              {article.text !== null && article.content.value === null && (
                <TextBlock text={article.text} />
              )}
              <Link
                to={
                  pageContext.category === "blog"
                    ? getBlogPath(lang)
                    : getNewsPath(lang)
                }
              >
                <Flex sx={{ flexDirection: "row" }}>
                  <ChevronLeft /> <p>{t.back}</p>
                </Flex>
              </Link>
            </Container>
          </>
        )}
      </LanguageContext.Consumer>
    </Layout>
  )
}

const Wrapper = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
`

export default Article

export const query = graphql`
  query ArticleQuery($slug: String!, $locale: String!) {
    article: datoCmsArticle(slug: { eq: $slug }, locale: { eq: $locale }) {
      ...ArticleAllSlugLocales
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      title
      content {
        value
        blocks {
          __typename
          ... on DatoCmsKeyTakeawaysRecap {
            id: originalId
            text
          }
          ... on DatoCmsBlogImage {
            id: originalId
            image {
              fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
      slug
      text
      locale
      id
      meta {
        firstPublishedAtIso: firstPublishedAt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      seo {
        title
        description
      }
    }
  }

  fragment ArticleAllSlugLocales on DatoCmsArticle {
    _allSlugLocales {
      value
      locale
    }
  }
`
